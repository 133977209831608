

.content {
    padding: 20px;
    max-width: 70vw;
    margin: auto;
}

.title {
    text-align: center;
    margin-bottom: -20px;
}

.description {
    margin-bottom: 15px;
}

.projects-title {
    margin-bottom: 5px;
    text-align: center;
}

.contact-title {
    margin-bottom: 30px;
    text-align: center;
}

.sub-title {
    font-size: 18px !important;
    line-height: 1.5;
    font-weight: 400 !important;
}

.footer {
    position: static;
    background: #333;
    color: #fff;
    text-align: center;
    padding: 5px;
    margin-top: 30px;
    z-index: 2;
}

.scroll-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

/* Styles for small screens */
@media (max-width: 768px) {
    .content {
        max-width: 90vw; /* Increase the width on small screens */
        margin-top: 0; /* Remove negative margin-top */
    }

    .footer {
        margin-top: 50px; /* Add more space above the footer on small screens */
    }
}

/* Styles for large screens */
@media (min-width: 769px) {
    .content {
        max-width: 70vw; /* Decrease the width on large screens */
    }

    .footer {
        margin-top: 30px; /* Reduce the space above the footer on large screens */
    }
}
