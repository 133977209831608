.drone .content {
    padding: 20px;
    max-width: 70vw;
    margin: auto;
}

.sub-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 80vw;
    margin-top: 60px !important;
}

.description {
    font-size: 16px;
    margin-bottom: 15px;
}




/* Styles for small screens */
@media (max-width: 768px) {
    .drone .content {
        padding: 10px;
        max-width: 90vw;
    }

    .description {
        padding: 0 5vw;
    }
}

/* Styles for large screens */
@media (min-width: 769px) {
    .drone .content {
        padding: 20px;
        max-width: 70vw;
    }

    
}

#services .title {
    text-align: center;
    margin-bottom: 20px;
}

.title .highlight {
    font-weight: bold;
    color: #2c3e50;
}

.description {
    text-align: center;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 2;
}

.sub-title {
    margin-bottom: 10px;
}

.services-list {
    margin-top: 60px;
    margin-bottom: 20px;
    list-style-type: none; /* Remove bullets from the list */
    background-color: whitesmoke;
    border-radius: 25px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}   

.service-item {
    margin-bottom: 40px; /* Adjust the spacing as needed */
    font-size: 1.2em;
    line-height: 1.5;
  }
  

.why-us {
    margin-bottom: 20px;
    line-height: 1.9;
    font-size: 18px;

}

#projects .project-title {
    margin-bottom: 20px;
    text-align: center;
}

#contact .contact-title {
    margin-bottom: 30px;
    text-align: center;
}

.sub-title{
    margin-top: 60px;

}
