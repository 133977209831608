.scroll-top-button {
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 1000;
}
@media (max-width: 768px) {
    .scroll-top-button {
      width: 40px;
      height: 40px;
    }
  }