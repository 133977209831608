.content-creation-page {}

.intro {
  margin-bottom: 10px;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 20px !important;
  line-height: 1.6;
  max-width: 90vw !important;
  margin-right: auto;
  margin-left: auto;
}

.offerings {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 70px;
}

.action-card {
  width: calc(50% - 20px);
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

@media screen and (max-width: 767px) {
  .action-card {
    width: calc(50% - 20px); /* Adjust as needed */
    margin-bottom: 20px; /* Add space between cards */
  }
  
  .card {
    margin: 20px; /* Adjust as needed */
  }

  .offerings {
    margin-bottom: 10px; /* Adjust as needed */
  }
}

@media screen and (min-width: 768px) {
  .action-card {
    width: calc(33.33% - 20px);
  }
  .intro {
    margin-bottom: 100px; /* Adjust as needed */
  }
}

.action-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.action-card-content {
  padding: 20px;
}

.action-card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.action-card-description {
  font-size: 16px;
  line-height: 1.6;
}

.action-card-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.action-card-button:hover {
  background-color: #0056b3;
}

.cta-container {
  margin-bottom: 70px;
}
