.app-bar {
    background-color: white;
    color: black;
   
}

.toolbar {
  display: flex;
  justify-content: space-between; /* Distribute items evenly */
  align-items: center; /* Center items vertically */

}


.typography-logo {
  font-family: monospace;
  font-weight: 700;
  letter-spacing: .3rem;
  color: inherit;
  text-decoration: none;
  margin-right: 2rem; /* Adjust the margin as needed */
}

.menu-icon-section,
.icons-section {
  display: flex;
  align-items: center; /* Center items vertically */
}

.menu-icon-section {
  flex-grow: 1; /* Allow menu icon to grow to take available space */
}

.icons-section {
  display: flex;
  align-items: center;
}

.box {
    flex-grow: 1;
    display: flex;
    width: 120px;
}

.icon-button {
    /* Add your styles for the icon button here */
}

.menu {
    display: block;
}

.typography-logo {
    margin-right: 2rem;
    display: flex;
    flex-grow: 1;
    font-family: monospace;
    font-weight: 700;
    letter-spacing: .3rem;
    color: inherit;
    text-decoration: none;
}

.box-pages {
    flex-grow: 1;
    display: none;
}

.button {
    margin: 2rem 0;
    color: black;
    display: block;
}

.logo {
    /* Add your styles for the logo here */
    width: 150px;
    height: auto;
    padding-top: 10px;
    align-items: center;
    padding-bottom: 10px;
    /* margin-right: auto;
    margin-left: auto;
    max-width: 100vw; */

}

.instagram-icon {
    color: black;
    /* top: 10px; */
}
.logo.shrink {
    width: 90px; /* Adjust the size as needed */
  }
  .logo {
    transition: width 0.3s ease; /* Add transition property for width */
  }
  .menu-item a,
.button a {
  text-decoration: none;
}
.menu-item a:visited,
.button a:visited {
  color: inherit; /* or specify the color you want */
}

.language-toggle {
  display: flex;
  align-items: center;
  color: black;
}

.flag {
    width: 60px;
    height: auto;
  }

.icons {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* margin-bottom: 5px; */
  
  }

.box-flags {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center ;
}
  


/* Styles for small screens */
@media (max-width: 600px) {
   
  
    .typography-logo {
      margin-right: 0; /* Remove margin */
    }
  
   
  
    .logo {
      width: 120px; /* Adjust logo size for smaller screens */
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }

    .icons {
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: center;
        /* margin-bottom: 5px; */
        max-width: 300px;
      
      }
    
    .box {
        width: 120px;
    }
  }
  