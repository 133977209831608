.container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-bottom: 60px;
}

.form {
    width: 100%;
}

.input-group {
    margin-bottom: 20px;
}

.input,
.textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.textarea {
    height: 100px;
}

.button {
    width: 60%;
    padding: 10px;
    font-size: 16px;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.button:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
}

/* Styles for small screens */
@media (max-width: 768px) {
    .container {
        max-width: 90%; /* Increase the width on small screens */
    }

    .button {
        width: 80%; /* Increase the button width on small screens */
    }
}

/* Styles for large screens */
@media (min-width: 769px) {
    .container {
        max-width: 500px; /* Decrease the width on large screens */
    }

    .button {
        width: 60%; /* Decrease the button width on large screens */
    }
}